import React from 'react';
import Layout from '@components/Layout';
import ErrorPage from '../scenes/ErrorPage/ErrorPage';

const NotFoundPage = () => {
  return (
    <Layout>
      <ErrorPage />
    </Layout>
  );
};

export default NotFoundPage;
